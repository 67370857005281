<template>
  <div class="shipments-page">
    <div class="flex items-center justify-between mb-base">
      <h2>{{$t('Task Lists')}}</h2>
      <div v-if="$store.state.AppActiveUser.userRole === 'head-of-fleet'">
        <vs-button
          @click="addTaskList()"
          color="primary"
          icon-pack="feather"
          icon="icon-plus"
          size="large"
        >
        </vs-button>
      </div>
    </div>
    <div class="lg:flex mb-5 items-center">
      <div class="page-title p-2">
        <div class="flex flex-wrap items-center">
          <div :style="$route.query.tab === tab ? 'background: #1C5BFE; color: white' : ''" class="relative vx-row m-2 py-2 pl-4 pr-5 cursor-pointer d-theme-dark-bg rounded-md hover:shadow-drop"
            v-for="tab in tabs" :key="tab.index" @click="changeTabFilter(tab)">
            <div class="text-sm">{{$t(tab)}}</div>
          </div>
        </div>
      </div>
      <div class="page-title p-2 lg:ml-5">
        <div class="flex flex-wrap items-center">
          <div :style="$route.query.type === type ? 'background: #1C5BFE; color: white' : ''" class="agents relative vx-row m-2 py-2 px-4 cursor-pointer d-theme-dark-bg rounded-lg items-center justify-center hover:shadow-drop"
            v-for="type in agentTypes" :key="type.index" @click="changeTypeFilter(type)"
            v-bind:class="$route.query.type && $route.query.type === type ? 'shadow-drop' : '' ">
            <span class="capitalize font-bold text-sm ">{{$t(type)}}</span>
          </div>
          <datepicker class="mx-2" :disabledDates="disabledDates" v-if="activeTab === 1" :placeholder="$t('Select a date')" v-model="filter.created"></datepicker>
        </div>
      </div>
      <div class="cursor-pointer ml-5">
        <div @click="loadFilter()" class="flex items-center available-action px-4 py-2">
          <span class="material-icons text-xl">filter_alt</span>
          <span class="ml-1">{{ $t('Filter') }}</span>
        </div>
      </div>
    </div>
    <head-component :noActions="true" :statusFilters="statusFilters" :selected="selected" @changeFilter="changeFilter" @filterValue="filterValue = $event" @loadShipments="loadTaskLists"></head-component>
    <shipblu-table
    :sst="true"
      search
      v-model="selected"
      @search="handleSearchCurrent"
      @keyup.enter.native="handleSearchCurrent"
      :data="taskLists"
      orders
      multiple
      pagination
      :max-items="maximumItems"
      :tableLoader="tableLoader"
    >
    <template slot="header">
      <div class="flex flex-wrap items-center gap-3 whitespace-nowrap">
        <export-tasklist-button />
      </div>
    </template>
      <template slot="thead">
        <shipblu-th>{{$t('Date')}}</shipblu-th>
        <shipblu-th>{{$t('Agent Name')}}</shipblu-th>
        <shipblu-th>{{$t('Agent Type')}}</shipblu-th>
        <shipblu-th v-if="$store.state.AppActiveUser.userRole === 'head-of-fleet'">{{$t('Created By')}}</shipblu-th>
        <shipblu-th>{{$t('Phone Number')}}</shipblu-th>
        <shipblu-th>{{$t('Packages')}}</shipblu-th>
        <shipblu-th>{{$t('Stops')}}</shipblu-th>
        <shipblu-th>{{$t('Deliveries')}}</shipblu-th>
        <shipblu-th>{{$t('Returns')}}</shipblu-th>
        <shipblu-th>{{$t('Exchanges')}}</shipblu-th>
        <shipblu-th>{{$t('Cash Requests')}}</shipblu-th>
        <shipblu-th>{{$t('Status')}}</shipblu-th>
        <shipblu-th>{{$t('Completed')}}</shipblu-th>
        <shipblu-th>
          <feather-icon icon="ChevronDownIcon" svgClasses="h-5 w-5"/>
        </shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" :color="`${getStyle(data[indextr])}`"
          :to="{ name: `${$store.state.AppActiveUser.userRole}-task-list`, params: { warehouseID: $route.params.warehouseID, taskListID: data[indextr].id }}">
          <shipblu-td class="sm:hidden block col-span-2 p-0 order-3">
            <!-- line -->
            <p style="background-color: #DCDCDC;height: 1px;"></p>
          </shipblu-td>

          <shipblu-td class="order-4" :data="data[indextr].agent">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Created')}}</p>
            <p class="sm:text-base text-sm">{{ common.splitYearOfDate(data[indextr].created) }}</p>
          </shipblu-td>

          <shipblu-td :data="data[indextr].tracking_number" class="text-left sm:order-3 order-16 sm:pointer-events-none">
            <div class="flex sm:static absolute top-3 gap-2 items-start justify-start transform-50" v-on:click.stop>
              <p class="sm:text-base text-lg flex gap-1 items-start justify-center">
                {{ data[indextr].merchant_agent ? data[indextr].merchant_agent.first_name + ' ' + data[indextr].merchant_agent.last_name : data[indextr].customer_agent ? data[indextr].customer_agent.first_name + ' ' + data[indextr].customer_agent.last_name : '' }}
              </p>
            </div>
          </shipblu-td>

          <shipblu-td class="order-5" :data="data[indextr].agent">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Agent Type')}}</p>
            <p class="sm:text-base text-sm">{{ data[indextr].merchant_agent ? 'First Mile Agent' : 'Last Mile Agent' }}</p>
          </shipblu-td>

          <shipblu-td class="order-6" :data="data[indextr].creator" v-if="$store.state.AppActiveUser.userRole === 'head-of-fleet'">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Created By')}}</p>
            <p class="sm:text-base text-sm">{{ data[indextr].creator ? data[indextr].creator.first_name + ' ' + data[indextr].creator.last_name : '' }}</p>
          </shipblu-td>

          <shipblu-td class="order-7" :data="data[indextr].phone">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Phone Number')}}</p>
            <span class="sm:text-base text-sm" v-if="data[indextr].merchant_agent">{{ data[indextr].merchant_agent.phone_number ? data[indextr].merchant_agent.phone_number : 'N/A' }}</span>
            <span class="sm:text-base text-sm" v-else-if="data[indextr].customer_agent">{{ data[indextr].customer_agent.phone_number ? data[indextr].customer_agent.phone_number : 'N/A' }}</span>
          </shipblu-td>

          <shipblu-td class="order-8" :data="data[indextr].packages">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Packages')}}</p>
            <p class="sm:text-base text-sm">{{ data[indextr].packages_count }}</p>
          </shipblu-td>

          <shipblu-td class="order-9" :data="data[indextr].stops">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Stops')}}</p>
            <p class="sm:text-base text-sm">{{ data[indextr].stops }}</p>
          </shipblu-td>

          <shipblu-td class="order-10" :data="data[indextr].deliveries">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Deliveries')}}</p>
            <p class="sm:text-base text-sm">{{ data[indextr].delivery_orders_count }}</p>
          </shipblu-td>

          <shipblu-td class="order-11" :data="data[indextr].returns">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Returns')}}</p>
            <p class="sm:text-base text-sm">{{ data[indextr].return_orders_count }}</p>
          </shipblu-td>

          <shipblu-td class="order-12" :data="data[indextr].exchanges">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Exchanges')}}</p>
            <p class="sm:text-base text-sm">{{ data[indextr].exchange_orders_count }}</p>
          </shipblu-td>

          <shipblu-td class="order-13" :data="data[indextr].cashCollection">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Cash Requests')}}</p>
            <p class="sm:text-base text-sm">{{ data[indextr].cash_collections_count }}</p>
          </shipblu-td>

          <shipblu-td class="order-14" :data="data[indextr].status" :style="`color: ${getStyle(data[indextr])}`" style="white-space: nowrap;">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Status')}}</p>
            <div class="flex" style="width: fit-content;" @click="removeAttribute($event)">
              <span class="capitalize border border-solid rounded-4xl px-5 py-1.5 leading-none sm:text-sm text-xs text-white" :style="`background-color: ${getStyle(data[indextr])}`">{{ $t(getStatusLabel(data[indextr])) }}</span>
            </div>
          </shipblu-td>

          <shipblu-td class="order-15" :data="data[indextr].completed">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Completed')}}</p>
            <p class="sm:text-base text-sm">{{ data[indextr].orders_completed_count + '/' + data[indextr].all_orders_count }}</p>
          </shipblu-td>

          <shipblu-td class="order-2" style="vertical-align: middle;">
            <div class="md:text-center text-right" @click="removeAttribute($event)">
              <vs-dropdown
                vs-trigger-click
                @click="selected = []"
                class="dd-actions cursor-pointer"
              >
                <span class="material-symbols-outlined text-2xl">more_horiz</span> 
                <vs-dropdown-menu style="width: 160px">
                  <vs-dropdown-item @click="viewTaskList(data[indextr])">
                    <span class="flex items-center">
                      <feather-icon
                        icon="EyeIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('View')}}</span>
                    </span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </div>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :noOptions="true" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" />
  </div>
</template>

<script>
import {sendRequest} from '../../http/axios/requestHelper.js'
import common  from '@/assets/utils/common'
import vSelect from 'vue-select'
import Datepicker from 'vuejs-datepicker'
import ShipbluTable from '../../layouts/components/NewShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import HeadComponent from '../merchant/components/Header.vue'
import ExportTasklistButton from './components/ExportTasklistButton.vue'
import ShipbluPagination from '../../layouts/components/ShipbluPagination.vue'

export default {
  data () {
    return {
      common,
      tabs: ['Today\'s task list', 'Previous task lists'],
      tabsDic: {'0': 'Today\'s task list', '1': 'Previous task lists'},
      tabsNameDic: {'Today\'s task list': 0, 'Previous task lists': 1},
      maximumItems: 10,
      taskLists: [],
      allTaskLists: [],
      selected: [],
      offset: 0,
      totalRows: 0,
      activeTab: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      allTaskListsOffset: 0,
      allTaskListsTotalRows: 0,
      allTaskListsCurrentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      searchVal: '',
      searchedCurrentValue: ' ',
      searchCurrnentInProgress: false,
      filter: {},
      agentTypes: ['All', 'First Mile', 'Last Mile'],
      statusFilters: [
        {
          name: 'All',
          value: 'all',
          color: '#1C5BFE'
        },
        {
          name: 'created',
          value: 'created'
        },
        {
          name: 'in progress',
          value: 'in_progress'
        },
        {
          name: 'completed',
          value: 'completed'
        },
        {
          name: 'failed',
          value: 'failed'
        }
      ],
      typeFilter: '',
      filters: [],
      filterValue: '',
      tableLoader: false,
      disabledDates: {
        from: new Date()
      }
    }
  },
  watch: {
    activeTab () {
      this.filter = {}
      this.$router.push({
        name: `${this.$store.state.AppActiveUser.userRole}-task-lists`,
        query: {
          page: this.currentPage,
          tab: this.tabsDic[this.activeTab],
          filter: this.filters,
          type: this.typeFilter
        }
      }).catch(() => {})
    },
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        name: `${this.$store.state.AppActiveUser.userRole}-task-lists`,
        query: {
          tab: this.tabsDic[this.activeTab],
          page: this.currentPage,
          filter: this.filters,
          type: this.typeFilter
        }
      }).catch(() => {})
      this.loadTaskLists()
    },
    '$route.params.warehouseID' () {
      this.searchVal = ''
      this.typeFilter = 'all'
      this.filters = []
      this.$router.push({
        query: {
          page: 1,
          tab: this.tabsDic['0'],
          filter: this.filters,
          type: this.typeFilter
        }
      }).catch(() => {})
      this.loadTaskLists()
    },
    '$route.query.filter' () {
      if (!this.$route.query.filter) {
        this.filters = []
        this.loadTaskLists()
      }
    }
  },
  methods: {
    removeAttribute (event) {
      event.preventDefault()
    },
    getStyle (data) {
      return common.getOrderStatusColor(data.status)
    },
    getStatusLabel (order) {
      return common.getStatusLabel(order, this.$store.state.AppActiveUser.userRole)
    },
    changeTabFilter (tab) {
      this.activeTab = this.tabsNameDic[tab]
    },
    changeFilter () {
      const filtersTemp = this.filters
      this.currentPage = 1
      this.filters = []
      filtersTemp.forEach(item => {
        this.filters.push(item)
      })
      this.filters.includes(this.filterValue) ? this.filters.splice(this.filters.indexOf(this.filterValue), 1) : this.filters.push(this.filterValue)
      if (this.filterValue === 'all') {
        this.filters = []
      }
      this.$router.push({
        name: `${this.$store.state.AppActiveUser.userRole}-task-lists`,
        query: {
          tab: this.tabsDic[this.activeTab],
          page: this.currentPage,
          filter: this.filters,
          type: this.typeFilter
        }
      }).catch(() => {})
      this.loadTaskLists()
    },
    changeTypeFilter (type) {
      this.typeFilter = type
      this.$router.push({
        name: `${this.$store.state.AppActiveUser.userRole}-task-lists`,
        query: {
          tab: this.tabsDic[this.activeTab],
          page: this.currentPage,
          filter: this.filters,
          type
        }
      }).catch(() => {})
    },
    handleSearchCurrent (search) {
      this.offset = 0
      this.currentPage = 1
      this.searchVal = search && search.target ? search.target.value : search
      return common.manageSearch(search, this.loadCurrentSearch)
    },
    loadCurrentSearch () {
      if (this.searchCurrnentInProgress || this.searchedCurrentValue === this.searchVal) {
        return
      }
      this.loadTaskLists()
    },
    loadTaskLists () {
      this.searchCurrnentInProgress = true
      this.tableLoader = true
      const query = `?offset=${this.offset}&warehouse_code=${this.$route.params.warehouseID}&limit=10&created=${(this.activeTab === 0 || this.activeTab === '0') ? new Date().toISOString().split('T')[0] : this.filter.created ? new Date(this.filter.created).toISOString().split('T')[0] : ''}&search=${this.searchVal}${this.typeFilter !== 'all' ? this.typeFilter === 'First Mile' ? '&merchant_agent=false' : '&customer_agent=false' : ''}&status=${this.filters}`
      sendRequest(true, false, this, `api/${this.$store.state.AppActiveUser.userRole === 'manager' ? `v1/warehouse/${this.$route.params.warehouseID}` : 'v2'}/task-sheets/${query}`, 'get', null, true,
        (response) => {
          this.selected = []
          this.taskLists = response.data.results
          this.totalRows = response.data.count
          this.tableLoader = false
          this.searchedCurrentValue = this.searchVal
        }
      )
      this.searchCurrnentInProgress = false
    },
    getOrderStatusColor (order) {
      return common.getOrderStatusColor(order.status)
    },
    addTaskList () {
      this.$router.push({
        name: 'head-of-fleet-add-task-list',
        params: {
          warehouseID: this.$route.params.warehouseID
        }
      }).catch(() => {})
    },
    viewTaskList (data) {
      this.$router.push({
        name: `${this.$store.state.AppActiveUser.userRole}-task-list`,
        params: {
          warehouseID: this.$route.params.warehouseID,
          taskListID: data.id
        }
      }).catch(() => {})
    },
    loadFilter () {
      this.currentPage = 1
      this.loadTaskLists()
    }
  },
  components: {
    vSelect,
    Datepicker,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    HeadComponent,
    ExportTasklistButton,
    ShipbluPagination
  },
  created () {
    this.activeTab = this.$route.query.tab ? this.tabsNameDic[this.$route.query.tab] : '0'
    this.offset = (this.currentPage - 1) * this.maximumItems
    this.currentPage = this.$route.query.page ? Number(this.$route.query.page) : 1
    this.filters = this.$route.query.filter ? typeof (this.$route.query.filter) === 'string' ? Array(this.$route.query.filter) : this.$route.query.filter : []
    this.typeFilter = this.$route.query.type ? this.$route.query.type : 'all'
    this.loadTaskLists()
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.con-pagination-table{
  display: none;
}
.agents {
  color: #fff;
  background: #CED6E0;
  border-radius: 6px;
}
</style>
